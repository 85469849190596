/**
 * The formatters-custom file is a place to define custom formatters or to
 * override any of the baked-in formatters like phone number formatting.
 *
 * e.g. You may write something like below
 * export function address(profile) {
 *   return `111 Yext Street`;
 * }
 */

export function property(profile, property) {
  if (!profile) return null;

  switch (property.toString().toUpperCase())
  {
    case 'NAME': return profile.name ?? profile.id ?? profile.uid ?? profile.type;
    case 'EXCERPT': return profile.c_excerpt ?? profile.c_rawContent;
    case 'URL': return profile.websiteUrl?.url ?? profile.website ?? profile.d_link;
    case 'AUTHOR': return profile.c_authorName;
    case 'CATEGORIES': return profile.c_boundlessCategories;
    case 'IMAGE': return profile.c_image;
    default: return profile[property];
  }
}

export function imageWithFallback(image, fallback) {
  if (image) {
    return Formatter.image(image, "800x1", true).url;
  } else {
    return fallback ?? "/static/assets/images/logo.webp";
  }
}

export function categories(categories) {
  if (!categories) return [];

  return categories.map((c) => ({ key: c.replace(/ +/g, '-').toLowerCase(), value: c }));
}


export function text(input, wordLimit) {
  if (!input) return "";
  if (!wordLimit) return input ?? "";

  let result = "";
  let words = input?.split(" ") ?? [];
  for (let i = 0; i < Math.min(words.length, wordLimit); i++) {
    result += words[i] + " ";
    if (i >= wordLimit - 1) {
      result += " ...";
    }
  }

  return result;
}